<template>
  <div class="miservers">
    <panes>
      <template v-slot:left>
        <div class="miserver-list">
          <h1>
            MagicINFO servers
            <base-button
              v-if="userCan('create', 'miserver')"
              v-on:click="create = true"
              class="right"
              isSmall
              type="THEMED"
              iconType="PLUS"
              title="Create MagicINFO server"
            />
          </h1>
          <MiserverTable v-on:rowClick="handleRowClick" :savedAt="savedAt" />
        </div>
      </template>
      <template v-slot:right>
        <div class="miserver-view">
          <MiserverEdit
            v-if="['miserverEdit'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :miserver-id="selected"
            v-on:saved="handleSaved"
          />
          <MiserverCreate
            v-else-if="create === true"
            v-on:cancel="create = false"
            v-on:saved="handleSaved"
          />
          <MiserverRead
            v-else-if="selected !== null && typeof selected === 'string' && create === false"
            :miserver-id="selected"
            v-on:deleted="handleDeleted"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import MiserverTable from '@/components/Table/Miserver.vue';
import MiserverRead from '@/components/Miserver/Read.vue';
import MiserverCreate from '@/components/Miserver/Create.vue';
import MiserverEdit from '@/components/Miserver/Edit.vue';
import Panes from '@/components/Panes/Panes.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';
import Utils from '../utils';

export default {
  name: 'Miserver',
  components: {
    MiserverTable,
    MiserverRead,
    MiserverCreate,
    MiserverEdit,
    BaseButton,
    Panes,
  },
  data() {
    return {
      selected: null,
      create: false,
      savedAt: null,
    };
  },
  methods: {
    handleRowClick(id) {
      this.selected = id;
    },
    handleSaved() {
      this.savedAt = Date.now();
      this.create = false;
    },
    handleDeleted() {
      this.handleSaved();
      this.selected = null;
    },
    userCan(...args) {
      Utils.userCan(...args);
    },
  },
  mounted() {
    if (typeof this.$route.params.miserver !== 'undefined') {
      this.selected = this.$route.params.miserver;
    }
  },
};
</script>

<style lang="scss" scoped>
.miservers {
  height: 100%;
}
.miserver-list {
  height: 100%;
}

.miserver-view {
  height: 100%;
  overflow-x: auto;
}
</style>
